import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;

  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  padding-top: 12px;
  padding-bottom: 12px;

  padding-left: 24px;
  padding-right: 24px;

  align-self: flex-end;

  bottom: 0;

  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: sticky;

  background-color: ${({ theme }) => theme.palette.gray._600};

  color: ${(props) => props.theme.palette.gray._100};

  overflow-anchor: auto;

  p {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  }

  @media ${media.small} {
    display: flex;
    height: 64px;

    padding-top: 0px;
    padding-bottom: 0px;
  }

  @keyframes slideFromBottom {
    0% {
      transform: translateY(64px);
      display: none;
    }

    50% {
      display: none;
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: 0.5s ease-out 0s 1 slideFromBottom;
`

export const Trailing = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  button:first-child {
    color: ${({ theme }) => theme.palette.gray._50};
  }

  button:first-child:hover {
    color: ${({ theme }) => theme.palette.gray._400};
  }
`
