import styled from "styled-components"

interface ContainerProps {
  $isConfigured: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.main : theme.palette.gray._400};
  background-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.lightest : theme.palette.gray._100};
  font-size: 16px;
  font-weight: bold;

  svg {
    color: ${({ $isConfigured, theme }) =>
      $isConfigured ? theme.palette.success.darker : theme.palette.gray._500};
  }
`
