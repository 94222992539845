import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { IGatewayDataTable } from "../features/gateways/gateways.interfaces"

const { access_token } =
  JSON.parse(
    sessionStorage.getItem(sessionStorage.key(0) as string) as string
  ) || {}

const token = access_token ? access_token : null

export const gatewaysApi = createApi({
  reducerPath: "gateways",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    fetchFn: async (...args) => {
      return fetch(...args)
    },
  }),
  endpoints: (build) => ({
    fetchGateways: build.query({
      query: () => {
        return {
          url: "/v1/proteu/collectorsProv",
          method: "GET",
        }
      },
      transformResponse: (responseData: IGatewayDataTable[]) => {
        const list = responseData.map((item) => ({
          ...item,
          value: [],
        }))
        return list
      },
    }),
  }),
})

export const { useFetchGatewaysQuery } = gatewaysApi
