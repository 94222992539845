import { Button } from "../../../ui/Button"
import { Container, Trailing } from "./styles"
import { Text } from "../../../ui/Text"

interface SnackBarProps {
  count: number
  onClose: () => void
}

export const SnackBar = ({ count, onClose }: SnackBarProps) => {
  return (
    <Container>
      <Text as='p'>{count} selecionados</Text>
      <Trailing>
        <Button variant='link' onClick={onClose}>
          Cancelar
        </Button>
      </Trailing>
    </Container>
  )
}
