import styled from "styled-components"
import { media } from "../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  gap: 2px;

  width: 100%;

  @media ${media.small} {
    max-width: 300px;
  }
`

export const WrapperIcon = styled.div`
  position: relative;
  width: 100%;
`

export const Icon = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: calc(24px - 10px);
  left: 1px;

  height: 20px;
  width: 32px;

  background-color: ${({ theme }) => theme.palette.gray._50};
`

export const SInput = styled.input<{ $hasIcon: boolean }>`
  width: 100%;
  height: 48px;

  background-color: ${({ theme }) => theme.palette.gray._50};

  outline: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.palette.gray._300};
  border-radius: 4px;

  text-indent: ${({ $hasIcon }) =>
    $hasIcon ? "calc(12px + 20px + 6px)" : "12px"};

  &::placeholder {
    color: ${(props) => props.theme.palette.gray._500};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.md.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.md.lineHeight};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    &::placeholder {
      color: ${(props) => props.theme.palette.gray._400};
    }

    p {
      color: ${(props) => props.theme.palette.gray._500};
    }
  }
`

export const Label = styled.p<{ $disable: boolean | undefined }>`
  color: ${({ $disable, theme }) =>
    $disable ? theme.palette.gray._500 : theme.palette.gray._600};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const HelperText = styled.p`
  color: ${(props) => props.theme.palette.gray._400};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const ErrorText = styled.p`
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`
