import { Title } from "../../../Title"
import { Actions, Container, Header, Services } from "./styles"
import { IconService } from "../../../IconService"
import { IconComponent } from "../../../ui/Icon"
import { Gear, Trash } from "phosphor-react"
import { SensorService } from "../../../../utils/entities/assets"

interface AssetWithSensorProps {
  servicesConfigured?: SensorService[]
  sensorName?: string
}

export const AssetWithSensor = ({
  servicesConfigured,
  sensorName = "",
}: AssetWithSensorProps) => {
  return (
    <>
      <Header>
        <Title label='Sensor' size='sm' weight='medium' />
        <Actions>
          <IconComponent
            icon={<Gear size={22} weight='bold' />}
            className='config-icon'
          />
          <IconComponent
            icon={<Trash size={22} weight='bold' />}
            className='delete-icon'
          />
        </Actions>
      </Header>
      <Container>
        <Title label={sensorName} size='md' weight='medium' />
        <Services>
          {servicesConfigured?.map((element, index) => (
            <IconService
              key={index}
              serviceType={element.name}
              isConfigured={element.isConfigured}
            />
          ))}
        </Services>
      </Container>
    </>
  )
}
