/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import styled from "styled-components"

export const getWidthString = (value?: number) => {
  if (!value) return

  let width = (value / 24) * 100
  return `width: ${width}%;`
}

type Bp = {
  $sm?: string
  $md?: string
  $lg?: string
  $xl?: string
}

const SColumn = styled.div<Bp>`
  float: left;
  min-height: 1px;
  box-sizing: border-box;
  padding: 0 4px;
  width: 100%;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media only screen and (min-width: 768px) {
    ${({ $md }) => $md && getWidthString(Number($md))}
  }

  @media only screen and (min-width: 960px) {
    ${({ $lg }) => $lg && getWidthString(Number($lg))}
  }

  @media only screen and (min-width: 1200px) {
    ${({ $xl }) => $xl && getWidthString(Number($xl))}
  }
`

type ColumnProps = {
  sm?: string
  md?: string
  lg?: string
  xl?: string
  children: React.ReactNode
}

const Column: React.FC<ColumnProps> = ({ children, sm, md, lg, xl }) => {
  return (
    <SColumn $sm={sm} $md={md} $lg={lg} $xl={xl}>
      {children}
    </SColumn>
  )
}

export default Column
