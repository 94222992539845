import styled from "styled-components"

export const Container = styled.div`
  padding: 0px 16px 16px 16px;
`

export const Header = styled.div`
  margin-bottom: 12px;
`

export const Content = styled.div``

export const MessageError = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: medium;
  text-align: center;
  margin: 16px 0;
`
