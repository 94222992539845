import styled from "styled-components"
import { media } from "../../utils/media-queries"

export const Container = styled.div`
  padding: 16px;
`

export const Header = styled.div`
  margin-bottom: 12px;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media ${media.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
`
