import { MdChevronRight } from "react-icons/md"
import { NavLink } from "react-router-dom"
import styled from "styled-components"

const OverlayNewMenu = styled.div<{ $open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 26, 33, 0.3);
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  pointer-events: ${({ $open }) => ($open ? "auto" : "none")};
  transition: opacity 0.45s ease;
  z-index: 11;
`

const LogoImg = styled.img`
  width: auto;
  padding: 24px 0px;
`

const NavbarWrapper = styled.nav<{ $open: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ $open }) => ($open ? 0 : "-100%")};
  width: 280px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.white};
  transition: 0.45s ease-out;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  transform: ${({ $open }) => ($open ? "translateX(0)" : "translateX(-100%)")};
  flex-direction: column;
  border-bottom: 4px solid ${(props) => props.theme.palette.secondary.main};
`

const NavBarContent = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100px;
  max-height: 550px;
  overflow-y: auto;

  a {
    text-decoration: none;
  }
`

const NavBarFooter = styled.div`
  position: fixed;
  background: ${(props) => props.theme.palette.white};
  bottom: 0;
  border-top: 1px solid ${(props) => props.theme.palette.gray._300};
`

const NavTitle = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  font-weight: 600;
  color: ${(props) => props.theme.palette.gray._800};
`

const CustomLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  transition: 0.2s;
  padding: 10px 12px;
  color: ${(props) => props.theme.palette.gray._800};

  .menu-icon {
    color: ${(props) => props.theme.palette.gray._500};
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.gray._300};
    color: ${(props) => props.theme.palette.secondary.main};
    border-color: ${(props) => props.theme.palette.success.darker};

    .menu-icon {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  &.active {
    .menu-icon {
      color: ${(props) => props.theme.palette.secondary.main};
    }
    background-color: ${(props) => props.theme.palette.gray._100};
    color: ${(props) => props.theme.palette.secondary.main};
    border-color: ${(props) => props.theme.palette.success.darker};
  }
`

const BoxUserInfo = styled.div`
  color: ${(props) => props.theme.palette.black};
  border: 1px solid ${(props) => props.theme.palette.gray._300};
  border-radius: 4px;
  margin: 24px 12px 12px 12px;
  padding: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;

  span {
    font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    height: 20px;
  }

  .user {
    font-size: ${(props) => props.theme.typography.text.xs.fontSize};
    color: ${(props) => props.theme.palette.gray._800};
  }

  .company {
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    color: ${(props) => props.theme.palette.gray._500};
    text-transform: uppercase;
  }
`

const SDropdown = styled.div<{ $open: boolean }>`
  white-space: nowrap;
  display: flex;
  color: ${(props) => props.theme.palette.black};
  flex-direction: column;
  clear: both;
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;

  a {
    font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray._800};
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    padding-left: 32px;
    position: relative;
    height: ${({ $open }) => ($open ? "44px" : "0px")};
    transition: height 0.75s ease;
    background-color: ${(props) => props.theme.palette.white};

    &:hover {
      background-color: ${(props) => props.theme.palette.gray._300};
      border-color: ${(props) => props.theme.palette.success.darker};
    }
  }
`

const SArrowIcon = styled(MdChevronRight)`
  display: block;
  margin-left: 4px;
  color: ${(props) => props.theme.palette.gray._500};
`

const SArrowContainer = styled.span<{ $isOpen: boolean }>`
  display: flex;
  justify-content: flex-end;
  transition: 0.4s;

  svg {
    transform: ${({ $isOpen }) =>
      !$isOpen ? "rotate(-270deg)" : "rotate(-90deg)"};
  }
`
const MenuDrop = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: height 0.3ms ease-in;
  color: ${(props) => props.theme.palette.gray._800};
  position: relative;

  .menu-content {
    padding: 10px 12px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .menu-icon {
      color: ${(props) => props.theme.palette.gray._500};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.gray._300};
    color: ${(props) => props.theme.palette.secondary.main};
    border-color: ${(props) => props.theme.palette.success.darker};

    .menu-icon {
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  &.active {
    background-color: ${(props) => props.theme.palette.gray._100};
    color: ${(props) => props.theme.palette.secondary.main};
    border-color: ${(props) => props.theme.palette.success.darker};
  }
`
const MenuDropContent = styled.div`
  display: flex;
`

const AppVersion = styled.span`
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  color: ${(props) => props.theme.palette.gray._500};
  text-transform: uppercase;
  text-align: center;
  padding: 8px;
`
const NotificationButton = styled.button`
  outline: none;
  border: none;
  background: none;
  position: relative;
`

const Badge = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  left: 25px;
  top: 5px;
`

const NotificationBadge = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.white};
  font-weight: bold;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  font-size: 8px;
  justify-content: center;
  align-items: center;
`

export {
  LogoImg,
  NavbarWrapper,
  NavBarContent,
  NavTitle,
  CustomLink,
  BoxUserInfo,
  OverlayNewMenu,
  NavBarFooter,
  SDropdown,
  SArrowContainer,
  SArrowIcon,
  MenuDrop,
  MenuDropContent,
  AppVersion,
  NotificationButton,
  Badge,
  NotificationBadge,
}
