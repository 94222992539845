import styled from "styled-components"
import { media } from "../../../../utils/media-queries"
import { Cell } from "../Cell"

export const Row = styled.tr<{ $rowsize: number; $checkbox: boolean }>`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.gray._200};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray._200};

  @media ${media.small} {
    td:first-child {
      padding-left: 16px;
      ${({ $checkbox }) => $checkbox && "width: auto"}
    }

    td:last-child {
      padding-right: 16px;
    }

    td {
      padding-left: 16px;
      width: calc(100% / ${(props) => props.$rowsize});
    }
  }
`

export const ContentRow = styled(Row)`
  display: none !important;

  @media ${media.small} {
    display: table-row !important;
  }
`

export const ContentCell = styled(Cell)`
  &::before {
    display: none;
  }

  padding: 0px !important;

  @media ${media.small} {
    display: none !important;
  }
`

export const ExpandedRowCard = styled.td`
  width: 100%;

  padding: 0px !important;

  background-color: ${({ theme }) => theme.palette.gray._100};
`

export const ExpandedCellCard = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.gray._100};
`
