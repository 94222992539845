export type GatewayItem = {
  id: number
  name: string
  bleHdrMac: string
  rssi: number
  mac: string
  dataRate: number
  lifetime: number
  ip: string
  version: string
}

export interface IGatewaysList {
  gateways: GatewayItem[]
}

export type GatewaysTable = {
  id: number
  name: string
  bleHdrMac: string
  rssi: number
  mac: string
  ip: string
  version: string
  time: number
  companyId: number
  ewMac: string
  value: {
    rssi: number
    lifetime: number
    dataRate: number
    ip_wifi: string
    mac: string
    ewMac: string
    version: string
    ssid: string
    time: number
  }
}

export type GatewaysTableConfiguration = [
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
]

export const gatewayTableRow: GatewaysTableConfiguration = [
  {
    label: "Gateway",
    render: (data: GatewaysTable) => data.name,
  },
  {
    label: "SSID",
    render: (data: GatewaysTable) => {
      const ssid = data.value?.ssid

      return ssid ? `${ssid}` : ` - `
    },
  },
  {
    label: "RSSI",
    render: (data: GatewaysTable) => {
      const rssi = data.value?.rssi

      return rssi ? `${rssi} dBM` : `0 dBM`
    },
  },
  {
    label: "Taxa de Dados",
    render: (data: GatewaysTable): string => {
      const tDate = data.value?.dataRate

      return tDate ? `-${tDate} dBM` : `0 dBM`
    },
  },
  {
    label: "Tempo Ativo",
    render: (data: GatewaysTable): string => {
      const gatewayTime = data.value?.lifetime
      if (gatewayTime) {
        const hours = Math.floor(gatewayTime / 3600)
        const minutes = Math.floor((gatewayTime % 3600) / 60)
        const seconds = Math.floor((gatewayTime % 3600) % 60)

        return `${hours}:${minutes}:${seconds}`
      }

      return "00:00:00"
    },
  },
]

export type GatewayDetailsConfiguration = [
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
  {
    label: string
    render: (param: GatewaysTable) => string
  },
]

export const gatewayTableRowDetails: GatewayDetailsConfiguration = [
  {
    label: "IP WIFI: ",
    render: (data: GatewaysTable) => {
      const ip = data.value?.ip_wifi

      return ip ? `${ip}` : ` - `
    },
  },
  {
    label: "BLE HDR-MAC: ",
    render: (data: GatewaysTable) => {
      const mac = data.value?.mac

      return mac ? `${mac}` : ` - `
    },
  },
  {
    label: "mac wifi: ",
    render: (data: GatewaysTable) => {
      const mac = data.ewMac

      return mac ? `${mac}` : ` - `
    },
  },
  {
    label: "Versão do Firmware: ",
    render: (data: GatewaysTable) => {
      const version = data.value?.version

      return version ? `${version}` : ` - `
    },
  },
  {
    label: "MQTT ID: ",
    render: (data: GatewaysTable) => {
      const mqttId = data.id

      return mqttId ? `${mqttId}` : ` - `
    },
  },
]
