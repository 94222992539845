import Container from "../../../components/ui/Container"
import Row from "../../../components/ui/Row"
import Column from "../../../components/ui/Column"
import { NewHeader } from "../../../components/NewHeader"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { useFetchGatewaysQuery } from "../../../store/store"
import { TableCss } from "../../../components/ui/TableCss"
import {
  gatewayTableRow,
  gatewayTableRowDetails,
} from "./utils/gatewaysDataTable"

export const NewGateways = () => {
  const { gateways } = useAppTranslate()

  const objPage = {
    page: {
      title: `${gateways.pageName}`,
    },
  }

  const {
    data: listGateways = [],
    error,
    isFetching,
  } = useFetchGatewaysQuery(1000)

  let content
  if (isFetching) {
    content = <div>Fetching...</div>
  } else if (error) {
    content = <div>Error...</div>
  } else {
    content = (
      <TableCss
        items={listGateways}
        configuration={gatewayTableRow}
        renderExpand={gatewayTableRowDetails}
      />
    )
  }

  return (
    <Container>
      <Row>
        <NewHeader page={objPage.page} />
      </Row>
      <Row>
        <Column md='24' lg='24' xl='24'>
          {content}
        </Column>
      </Row>
    </Container>
  )
}
