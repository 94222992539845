import { Title } from "../../../../../../components/Title"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { Container } from "./styles"

export const HistoryHeader = () => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <Title label={assets.history} size='md' weight='medium' />
    </Container>
  )
}
