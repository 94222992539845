import styled from "styled-components"

enum fontWight {
  bold = "fontWeightBold",
  semibold = "fontWeightSemibold",
  regular = "fontWeightRegular",
  medium = "fontWeightMedium",
}

interface TitleProps {
  $fontSize: "lg" | "md" | "sm" | "xs"
  $weight: "bold" | "semibold" | "regular" | "medium"
}

export const Title = styled.span<TitleProps>`
  font-size: ${({ theme, $fontSize }) => theme.typography.text[$fontSize]};
  font-weight: ${({ theme, $weight }) => theme.typography[fontWight[$weight]]};
  color: ${(props) => props.theme.palette.gray._600};
`
