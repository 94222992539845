import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${media.medium} {
    flex-direction: row;
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

export const Services = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  margin-top: 12px;
  @media ${media.medium} {
    justify-content: flex-end;
    margin-top: 0px;
    align-items: end;
  }
`

export const Actions = styled.section`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  right: 5px;
  .config-icon {
    color: ${({ theme }) => theme.palette.gray._500};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.palette.gray._600};
    }
  }

  .delete-icon {
    color: ${({ theme }) => theme.palette.error.main};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.palette.error.darker};
    }
  }

  @media ${media.medium} {
    justify-content: flex-end;
  }
`
