import { useCallback, useEffect, useMemo, useState } from "react"

interface State {
  [id: string]: {
    checked: boolean
    expanded: boolean
  }
}

interface UseTableProps<T> {
  data: T[]
}

export const useTable = <T extends { id: string | number }>({
  data,
}: UseTableProps<T>) => {
  const isEmpty = !data || data.length === 0

  const [filtered, setFiltered] = useState<T[]>(data)
  const [sortBy, setSortBy] = useState<string | number | symbol | undefined>(
    undefined
  )

  const [selected, setSelected] = useState<State>({})

  const compare = (key: string | number | symbol | undefined) => {
    return (a: T, b: T) => {
      if (key === undefined) return 0

      const first = (a as never)[key]
      const second = (b as never)[key]

      if (first < second) return -1
      if (first > second) return 1

      return 0
    }
  }

  const handleSortIcon = (key: string | number | symbol | undefined) => {
    setSortBy(key)
    setFiltered(data.sort(compare(key)))
  }

  const initializeState = useCallback(() => {
    const empty: State = {}

    data.forEach(
      (row) => (empty[row.id.toString()] = { checked: false, expanded: false })
    )

    setSelected(empty)
  }, [data])

  const uncheckAll = () => {
    setSelected((state) => {
      data.forEach((row) => (state[row.id.toString()].checked = false))
      return { ...state }
    })
  }

  const checkAll = () => {
    setSelected((state) => {
      data.forEach((row) => (state[row.id.toString()].checked = true))
      return { ...state }
    })
  }

  const check = (id: string | number) => {
    setSelected((state) => {
      state[id.toString()].checked = !state[id.toString()].checked
      return { ...state }
    })
  }

  const getCheckState = (id: string | number) => {
    if (Object.keys(selected).length === 0 || !selected[id.toString()])
      return false
    return selected[id.toString()].checked
  }

  const expand = (id: string | number) => {
    setSelected((state) => {
      state[id.toString()].expanded = !state[id.toString()].expanded
      return { ...state }
    })
  }

  const getExpandState = (id: string | number) => {
    if (Object.keys(selected).length === 0 || !selected[id.toString()])
      return false
    return selected[id.toString()].expanded
  }

  useEffect(() => {
    initializeState()
  }, [data, initializeState])

  const countChecked = useMemo(() => {
    if (Object.keys(selected).length === 0) return 0

    let count = 0

    data.forEach((row) => {
      if (selected[row.id.toString()] && selected[row.id.toString()].checked)
        count++
    })

    return count
  }, [data, selected])

  return {
    isEmpty,
    filtered,
    sortBy,
    countChecked,
    handleSortIcon,
    check,
    getCheckState,
    expand,
    getExpandState,
    uncheckAll,
    checkAll,
  }
}
