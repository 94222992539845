import styled from "styled-components"

export const AssetsContainer = styled.div`
  font-family: ${(props) => props.theme.typography.fontFamily};
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 16px;
`
export const Content = styled.div`
  height: 700px;
  overflow-y: auto;
`

export const MessageContainer = styled.div`
  font-size: medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 273px;
  padding: 10px 0px 10px 0px;
  gap: 10px;
`

export const HistoryComponent = styled.div`
  border: 1px solid red;
  background-color: ${(props) => props.theme.palette.gray._50};
  margin-top: 28px;
  height: 700px;
  margin-right: 10px;
  border: 1px solid ${(props) => props.theme.palette.gray._400};
  border-radius: 4px;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
