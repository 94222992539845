import { createGlobalStyle } from "styled-components"
import "react-toastify/dist/ReactToastify.css"

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;  
        box-sizing: border-box;
    }

    html {
        font-family: ${(props) => props.theme.typography.fontFamily};
        font-size: 62.5%;
    }

    html ::-webkit-scrollbar {
       width: 8px;
    }
    html ::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: ${(props) => props.theme.palette.primary.main};;
    }
    html ::-webkit-scrollbar-track {
        background: ${(props) => props.theme.palette.gray._100};;
    }

    body {
        margin: 0;
        padding: 0;
        height: 100vh;
        font-size: ${(props) => props.theme.font.sizes.medium};
        @media (max-width: 1400px) {
            font-size: ${(props) => props.theme.font.sizes.small};
        }
        background-color: ${(props) => props.theme.palette.gray._100};
    }

    button {
        cursor: pointer;
    }

    a {
        color: inherit;
        text-decoration:none;
    }

    h4 {
        font-family: ${(props) => props.theme.typography.display.xs.fontFamily};
        font-size: ${(props) => props.theme.typography.display.xs.fontSize};
        line-height: ${(props) => props.theme.typography.display.xs.lineHeight};
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray._800};
    }

    h5 {
        font-family: ${(props) => props.theme.typography.text.md.fontFamily};
        font-size: ${(props) => props.theme.typography.text.md.fontSize};
        line-height: ${(props) => props.theme.typography.text.md.lineHeight};
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray._800};
    }

    h6 {
        font-family: ${(props) => props.theme.typography.text.sm.fontFamily};
        font-size: ${(props) => props.theme.typography.text.sm.fontSize};
        line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray._800};
    }

    .m-l-10 {
        margin-left: 10px;
    }


    .m-t-26 {
        margin-top: 27px;
    }

`

export default GlobalStyles
