/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from "react"
import {
  TableView,
  TableContainer,
  TableHeader,
  TableColumn,
  TableFooter,
} from "./tableCss.styled"
import { RowsTableCss } from "./components/Rows"
import { BoxActionTable } from "./components/BoxAction"
import { useWebSocket } from "../../../hooks/useWebSocket"

interface TableProps<T, U, W> {
  items: T[]
  configuration: U[]
  renderExpand: W[]
}

export const TableCss = <
  T extends Record<string, any>,
  U extends Record<string, any>,
  W extends Record<string, any>,
>(
  props: TableProps<T, U, W>
) => {
  const { items, configuration, renderExpand } = props
  const { messages } = useWebSocket()

  const renderTable = configuration.map((item) => {
    return (
      <div key={item.label} className='header-cell'>
        {item.label}
      </div>
    )
  })

  const [itemsSelected, setItemsSelected] = useState<number[]>([])

  const updateSelectedValues = useCallback(
    (num: number, check: boolean) => {
      const index = itemsSelected.indexOf(num)

      if (index < 0) {
        setItemsSelected([...itemsSelected, num])
      } else {
        setItemsSelected((arr) => arr.filter((item) => item !== num))
      }
    },
    [itemsSelected]
  )

  const renderRows = items.map((item) => {
    const dataSocket = messages(item.bleHdrMac)
    const lastGatewayData = dataSocket?.slice(-1)
    const gateway = lastGatewayData ? lastGatewayData[0] : {}
    const combined = {
      ...item,
      value: gateway,
    }

    return (
      <RowsTableCss
        row={combined}
        config={configuration}
        onChange={updateSelectedValues}
        renderExpandContent={renderExpand}
        key={item.id}
      />
    )
  })

  return (
    <TableView>
      <TableContainer>
        <TableHeader>
          <div className='table-row'>
            <div className='checkbox-header header-cell' />
            {renderTable}
            <div className='expand-header header-cell' />
          </div>
        </TableHeader>
        <TableColumn>{renderRows}</TableColumn>
      </TableContainer>
      <TableFooter>
        <BoxActionTable count={itemsSelected.length} />
      </TableFooter>
    </TableView>
  )
}
