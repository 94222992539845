import styled from "styled-components"
import { media } from "../../../../utils/media-queries"
import { Font } from "../../../../styles/mixins/fonts.mixins"

export const ListDetailsGateways = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  ${Font({ size: "text.sm", lineHeight: "text.sm", fontWeight: "medium" })}

  .list-gateway-info {
    display: flex;
    justify-content: center;
    padding: 6px 0px;

    span {
      text-transform: uppercase;
      padding-right: 5px;
    }
  }

  @media ${media.small} {
    display: ${({ $isOpen }) => ($isOpen ? "table-row" : "none")};
    position: relative;
    height: 44px;

    .table-row-expand-group {
      position: absolute;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;

      .list-items {
        display: flex;
        flex-direction: row;
      }
    }

    .list-gateway-info {
      display: flex;
      padding: 0 12px;
    }
  }
`
