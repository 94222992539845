import { useContext } from "react"
import { TabsContext } from "../Tabs"
import { Container } from "./styles"

type TabOwnProps = {
  index?: number
}

type TabProps = TabOwnProps & React.ComponentProps<"div">

export const Tab = ({ children, index, ...props }: TabProps) => {
  const state = useContext(TabsContext)

  if (!state || index === undefined) return <></>

  const updateIndex = (value: number) => {
    state.setSelectedIndex(value)
  }

  return (
    <Container
      selected={index === state.selectedIndex}
      onClick={() => updateIndex(index)}
    >
      {children}
    </Container>
  )
}
