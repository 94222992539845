import { InputHTMLAttributes, PropsWithRef, forwardRef } from "react"
import { Label, Container, Header, Option, Select, ErrorText } from "./styles"
import { Button } from "../../ui/Button"

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string
  error?: string
  options: (string | number)[]
  renderLabel?: (option: string | number) => string
}

export const InputSelect = forwardRef<
  HTMLSelectElement,
  PropsWithRef<SelectProps>
>(({ label, disabled, error, options, renderLabel, ...props }, ref) => {
  return (
    <Container>
      <Header>
        {label && <Label $disable={disabled}>{label}</Label>}
        <Button variant='link'>Adicionar Tipo</Button>
      </Header>
      <Select name='select' {...props} defaultValue='' required ref={ref}>
        <Option value={""} disabled>
          Selecione uma opção
        </Option>
        {options.map((option, index) => (
          <Option key={index} value={option}>
            {renderLabel ? renderLabel(option) : option}
          </Option>
        ))}
      </Select>

      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  )
})

InputSelect.displayName = "InputSelect"
