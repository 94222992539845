import styled from "styled-components"

export const Container = styled.td`
  padding-top: 12px;
  padding-bottom: 12px;

  p {
    color: ${(props) => props.theme.palette.gray._700};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  }

  background-color: ${(props) => props.theme.palette.gray._50};
`
