import styled from "styled-components"
import { media } from "../../../utils/media-queries"
import { Font } from "../../../styles/mixins/fonts.mixins"

const TableView = styled.div`
  @media ${media.small} {
    display: table;
    height: 85vh;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.palette.gray._400};
  }
`

const TableContainer = styled.div`
  width: 100%;

  @media ${media.small} {
    display: table;
    table-layout: fixed;
  }
`

const TableHeader = styled.div`
  display: none;

  @media ${media.small} {
    display: table-header-group;
    background-color: ${(props) => props.theme.palette.gray._200};

    .header-cell {
      display: table-cell;
      padding: 12px 0px;

      ${Font({ size: "text.sm", lineHeight: "text.sm", fontWeight: "medium" })}
    }

    .expand-header,
    .checkbox-header {
      width: 52px;
      display: table-cell;
    }

    .table-row {
      display: table-row;
      width: 100%;
    }

    .table-row > div {
      display: table-cell;
    }
  }
`

const TableColumn = styled.div`
  display: flex;
  flex-direction: column;

  .mobile-view {
    background-color: ${(props) => props.theme.palette.gray._200};
    width: 50%;
    padding: 12px 16px;

    color: ${(props) => props.theme.palette.gray._700};
    ${Font({ size: "text.sm", lineHeight: "text.sm", fontWeight: "regular" })}

    @media ${media.small} {
      display: none;
    }
  }

  .mobile-view-over {
    background-color: ${(props) => props.theme.palette.white};
    padding: 12px 16px;

    @media ${media.small} {
      padding: 0;
    }
  }

  .table-row {
    margin: 16px 0px;
    border: 1px solid ${(props) => props.theme.palette.gray._400};

    @media ${media.small} {
      display: table-row;
      width: 100%;
      padding: 0;
    }

    .cell-column {
      display: flex;
      border-top: 1px solid ${(props) => props.theme.palette.gray._400};

      p {
        ${Font({
          size: "text.sm",
          fontWeight: "medium",
        })}
      }

      @media ${media.small} {
        display: table-cell;
        padding: 12px 0;
        vertical-align: middle;
      }
    }

    .gateway-name {
      display: flex;
      align-items: baseline;
    }

    .expand-content-mobile {
      display: flex;
      justify-content: center;
      padding: 0%;

      @media ${media.small} {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .table-body {
    display: table-row-group;
  }

  .table-row > div {
    background-color: ${(props) => props.theme.palette.white};
  }

  @media ${media.small} {
    display: table-row-group;
  }
`

const CellContent = styled.div<{ $isOpen: boolean }>`
  color: ${(props) => props.theme.palette.gray._600};
  transition: height 0.3ms ease-in;
  padding: 12px;

  @media ${media.small} {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .arrow-container {
    background-color: ${(props) => props.theme.palette.white};
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    svg {
      animation: spin 1s infinite linear;

      transform: ${({ $isOpen }) =>
        !$isOpen ? "rotate(0deg)" : "rotate(-180deg)"};
    }
  }
`

const BoxBar = styled.div<{ $iShow: boolean }>`
  display: ${({ $iShow }) => ($iShow ? "flex" : "none")};
  height: 64px;
  width: 100%;
  position: sticky;
  bottom: 0;
`

const BoxBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: ${(props) => props.theme.palette.gray._600};
  color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.gray._600};
  border-radius: 8px;

  p {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  }
`

const TableFooter = styled.div`
  display: flex;

  @media ${media.small} {
    background-color: ${(props) => props.theme.palette.gray._50};
    display: table-footer-group;
    position: sticky;
    bottom: 0;
    min-height: 10px;
  }
`

export {
  TableView,
  TableContainer,
  TableHeader,
  TableColumn,
  TableFooter,
  CellContent,
  BoxBar,
  BoxBarContainer,
}
