import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: start;

  margin-top: 28px;
  margin-right: 10px;
  margin-left: 10px;
`

export const AssetsContainer = styled.div<{ open: boolean }>`
  background-color: ${(props) => props.theme.palette.gray._50};

  overflow: hidden;

  border: 1px solid ${(props) => props.theme.palette.gray._400};
  border-radius: 4px;

  box-sizing: content-box;

  height: 700px;

  max-width: 274px;

  @keyframes showTree {
    0% {
      max-width: 0;
    }
    100% {
      max-width: 274px;
    }
  }

  @keyframes hideTree {
    0% {
      opacity: 1;
      max-width: 274px;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      display: none;
      max-width: 0;
    }
  }

  animation: ${(props) =>
    props.open
      ? "showTree 750ms ease-out forwards"
      : "hideTree 750ms ease-out forwards"};
`

export const AssetsHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  border-bottom: 1px solid ${(props) => props.theme.palette.gray._400};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  padding-right: 10px;

  height: 44px;

  background-color: ${(props) => props.theme.palette.gray._100};
`

export const ChildrenContainer = styled.div`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 4px;
  }
`

export const IconContainer = styled.div<{ open: boolean }>`
  width: 44px;

  display: ${({ open }) => (open ? "none" : "block")};

  @keyframes showIcon {
    0% {
      opacity: 0;
      width: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      width: 44px;
    }
  }

  animation: ${({ open }) => !open && "showIcon 1s forwards"};
`
