import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Title } from "../../../Title"
import { Button } from "../../../ui/Button"
import { Container, Text } from "./styles"

export const AssetWithoutSensor = () => {
  const { assets } = useAppTranslate()

  return (
    <>
      <Title label='Sensor' size='sm' weight='medium' />
      <Container>
        <Text>{assets.selectUsers}</Text>
        <Button variant='link'>{assets.addUsers}</Button>
      </Container>
    </>
  )
}
