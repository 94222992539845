import { TitleAndText } from "../../TitleAndText"
import { Container, Header, Content } from "../styles"
import { Skeleton } from "@mui/material"

export const AssetPropertiesSkeleton = () => {
  const skeletonWidth = 100
  const skeletonHeight = 20
  const skeletonItems = 2

  return (
    <Container>
      <Header>
        <Skeleton
          variant='text'
          width={skeletonWidth}
          height={skeletonHeight}
        />
      </Header>

      <Content>
        {Array.from({ length: skeletonItems }, (_, index) => (
          <TitleAndText skeleton key={`skeleton-${index}`} />
        ))}
      </Content>
    </Container>
  )
}
