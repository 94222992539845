import styled, { css } from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const CheckboxContainer = styled.div<{ $isCheck: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${media.small} {
    height: 0;
  }
`

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  margin: -1px;
  opacity: 0;
  padding: 0;
  display: none;
`

export const StyledCheckbox = styled.label<{ $isCheck: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.theme.palette.gray._400};
  border-radius: 4px;
  background: ${(props) => props.theme.palette.gray._50};

  ${({ $isCheck }) =>
    $isCheck &&
    css`
      background-color: ${(props) => props.theme.palette.secondary.main};
      color: ${(props) => props.theme.palette.gray._50};
      border: none;
    `}

  svg {
    display: ${({ $isCheck }) => ($isCheck ? "flex" : "none")};
  }
`
