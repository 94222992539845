import { TreeItem } from "react-complex-tree"

export enum AssetType {
  SITE = 1,
  MOTOR = 2,
  ADL = 3,
}

export interface AssetData {
  id: string
  name: string
  type: AssetType
}

export interface TreeAssetItem extends TreeItem<AssetData> {}

export interface AssetTreeData extends Record<string, TreeAssetItem> {}

export enum AssetHistoryDataType {
  CONFIGURATION_CHANGED = 1,
  SENSOR_REMOVED = 2,
  SENSOR_ADDED = 3,
}
export interface AssetHistoryData {
  type: AssetHistoryDataType
  createdAt: number
  description: string
}

export interface AssetPropertiesDTO {
  [key: string]: string
}

export interface SensorService {
  name:
    | "temperature"
    | "rms2"
    | "rmms"
    | "tilt"
    | "fft"
    | "accRaw"
    | "_4t20"
    | "ntc"
    | "pot"
  isConfigured: boolean
}

export interface Sensor {
  name: string
  serialNumber: string
  services: SensorService[]
}

export interface AssetDetailsData {
  id: string
  name: string
  type: number
  createdAt: number
  properties: AssetPropertiesDTO
  sensor: Sensor
  description: string
  historicalData: AssetHistoryData[]
}

export const getProperties = (type: AssetType) => {
  switch (type) {
    case AssetType.SITE:
      return ["location", "area", "city"]
    case AssetType.MOTOR:
      return ["voltage", "power", "current"]
    case AssetType.ADL:
      return ["adl1", "adl2", "adl3"]
  }
}

export const getTypeName = (type: string | number) => {
  switch (Number(type)) {
    case AssetType.SITE:
      return "Site"
    case AssetType.MOTOR:
      return "Motor"
    case AssetType.ADL:
      return "ADL"
  }
}
