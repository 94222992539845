import styled from "styled-components"

type RoundDivProps = {
  $type: "notification" | "message"
  $status: "read" | "unread"
}

export const RoundDiv = styled.div<RoundDivProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.primary.lightest};
  border: 2px solid ${(props) => props.theme.palette.primary.lighter};
  color: ${(props) => props.theme.palette.secondary.main};

  ${(props) =>
    props.$type === "message" &&
    `
        display: none;
    `}

  ${(props) =>
    props.$status === "read" &&
    `
      background-color: ${props.theme.palette.gray._100};
      border: 2px solid ${props.theme.palette.gray._100};
      color: ${props.theme.palette.gray._600};

    `}
`
