import { TreeDataProvider, TreeItem, TreeItemIndex } from "react-complex-tree"
import {
  AssetData,
  AssetTreeData,
  TreeAssetItem,
} from "../../../utils/entities/assets"

export class CustomDataProvider implements TreeDataProvider {
  private data: AssetTreeData
  private treeChangeListeners: Set<(changedItemIds: TreeItemIndex[]) => void> =
    new Set()

  constructor(items: Record<TreeItemIndex, TreeAssetItem>) {
    this.data = items
  }

  async getTreeItem(itemId: TreeItemIndex) {
    return this.data[itemId]
  }

  async onChangeItemChildren(
    itemId: TreeItemIndex,
    newChildren: TreeItemIndex[]
  ) {
    this.data[itemId].children = newChildren
    this.notifyTreeChange([itemId])
  }

  onDidChangeTreeData(listener: (changedItemIds: TreeItemIndex[]) => void) {
    this.treeChangeListeners.add(listener)
    return {
      dispose: () => this.treeChangeListeners.delete(listener),
    }
  }

  async onRenameItem(item: TreeItem, name: string) {
    this.data[item.index].data.name = name
  }

  injectItem(parent: TreeItemIndex, data: AssetData) {
    const item = {
      index: data.id,
      data,
      isFolder: false,
      children: [],
      canMove: false,
      canRename: false,
    }

    const copy_tree = structuredClone(this.data)
    copy_tree[data.id] = item

    const parentItem = this.data[parent]

    if (parentItem) {
      this.data = this.insertChildren(parentItem, copy_tree, data.id)

      this.notifyTreeChange([parent])
    }

    this.notifyTreeChange(["master"])
  }

  private notifyTreeChange(changedItemIds: TreeItemIndex[]) {
    this.treeChangeListeners.forEach((listener) => listener(changedItemIds))
  }

  private insertChildren(
    parentItem: TreeAssetItem,
    tree: AssetTreeData,
    itemId: string
  ) {
    let children = []

    if (parentItem.children) {
      children = [...parentItem.children, itemId]
    } else {
      children = [itemId]
    }

    return Object.assign(tree, {
      [parentItem.data.id]: {
        ...parentItem,
        isFolder: true,
        children,
      },
    })
  }
}
