import { useEffect, useState } from "react"
import { BoxBar, BoxBarContainer } from "../../tableCss.styled"

type TableBarProps = {
  count: number
}

export const BoxActionTable = ({ count }: TableBarProps) => {
  const [showBox, setShowBox] = useState(false)

  useEffect(() => {
    return count > 0 ? setShowBox(true) : setShowBox(false)
  }, [count])

  return (
    <BoxBar $iShow={showBox}>
      <BoxBarContainer>
        <div>
          <p>Selecionados: {count}</p>
        </div>
      </BoxBarContainer>
    </BoxBar>
  )
}
