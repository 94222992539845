import { Folder, MapPinSimpleArea, Engine } from "@phosphor-icons/react"
import * as S from "./styles"
import { AssetType } from "../../../utils/entities/assets"

const getIconByType = (type: number) => {
  switch (type) {
    case AssetType.SITE:
      return MapPinSimpleArea
    case AssetType.MOTOR:
      return Engine
    default:
      return Folder
  }
}

export const AssetIcon = ({ type }: { type: number }) => {
  const Icon = getIconByType(type)

  return (
    <S.AssetIcon>
      <Icon size={20} />
    </S.AssetIcon>
  )
}
