import { InputHTMLAttributes, ReactNode, forwardRef } from "react"
import {
  Container,
  ErrorText,
  HelperText,
  Icon,
  Label,
  SInput,
  WrapperIcon,
} from "./styles"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  helper?: string
  error?: string
  icon?: ReactNode
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, helper, error, icon, ...props }, ref) => {
    return (
      <Container>
        {label && <Label $disable={props.disabled}>{label}</Label>}

        <WrapperIcon>
          {icon && <Icon>{icon}</Icon>}
          <SInput $hasIcon={icon !== undefined} ref={ref} {...props} />
        </WrapperIcon>

        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          helper && <HelperText>{helper}</HelperText>
        )}
      </Container>
    )
  }
)

Input.displayName = "Input"
