import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

export const WrapperText = styled.div`
  border: 1px solid ${(props) => props.theme.palette.gray._300};
  border-radius: 4px;
  padding: 12px;
  height: 100%;
  font-size: medium;
  overflow: hidden;
`
