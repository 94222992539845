import styled from "styled-components"

export const TreeAssetsContainer = styled.div`
  min-width: 274px;
`
export const TreeAssetsSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`
