import styled from "styled-components"
import { media } from "../../utils/media-queries"

type CardIconAndTitleProps = {
  read: boolean
}

export const CardWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.gray._50};
  width: 100%;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  display: flex;
  gap: 8px;
`

export const CardIconAndTitle = styled.div<CardIconAndTitleProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: ${(props) => props.theme.typography.text.sm};

  ${(props) =>
    props.read &&
    `
      color: ${props.theme.palette.gray._700};
    `}
`

export const CardContent = styled.div`
  font-size: ${(props) => props.theme.typography.text.xs};
  margin-top: 10px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  @media ${media.medium} {
    font-size: ${(props) => props.theme.typography.text.sm};
  }
`

export const Wrapper = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.typography.text.xs};
`

export const CardHeader = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`
export const CardTag = styled.div`
  font-size: ${(props) => props.theme.typography.text.sm};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
`

export const CardTitle = styled.span`
  font-size: ${(props) => props.theme.typography.text.xs};
`
