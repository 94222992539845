import Skeleton from "@mui/material/Skeleton"
import { TreeAssetsSkeletonContainer } from "./styles"

export const TreeAssetsSkeleton = () => {
  return (
    <TreeAssetsSkeletonContainer>
      <Skeleton variant='rounded' width={260} />
      <Skeleton variant='rounded' width={260} />
      <Skeleton variant='rounded' width={260} />
    </TreeAssetsSkeletonContainer>
  )
}
