import { Siren, Trash } from "@phosphor-icons/react"
import { Card } from "../../../../../../components/Card"
import { AssetHistoryData } from "../../../../../../utils/entities/assets"
import { Divider } from "../../../../../../components/ui/Divider"
import React from "react"

interface HistoryContentProps {
  historyData: AssetHistoryData[]
}

const historyType = {
  1: {
    icon: <Siren />,
    title: "Configuração alterada",
  },
  2: {
    icon: <Trash />,
    title: "Sensor removido",
  },
  3: {
    icon: <Siren />,
    title: "Sensor adicionado",
  },
} as const

const getHistoryType = (type: keyof typeof historyType) => {
  return historyType[type] || { icon: <Siren />, title: "Ação desconhecida" }
}

export const HistoryContent = ({ historyData }: HistoryContentProps) => {
  return (
    <>
      {historyData.map((data, index) => {
        const { icon, title } = getHistoryType(
          data.type as keyof typeof historyType
        )

        return (
          <React.Fragment key={index}>
            <Card
              title={title}
              icon={icon}
              date={new Date(data.createdAt)}
              type='notification'
            >
              {data.description}
            </Card>
            <Divider />
          </React.Fragment>
        )
      })}
    </>
  )
}
