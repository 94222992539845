import React from "react"

type TextProps<C extends React.ElementType> = {
  as?: C
  children: React.ReactNode
  fontSize?: "small" | "medium" | "large"
} & React.ComponentPropsWithoutRef<C>

export const Text = <C extends React.ElementType = "span">({
  as,
  children,
  fontSize = "small",
  ...restProps
}: TextProps<C>) => {
  const Component = as || "span"

  return <Component {...restProps}>{children}</Component>
}
