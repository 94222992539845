import { AssetDescriptionSkeleton } from "../../../../../components/AssetDescription/Skeleton"
import { AssetPropertiesSkeleton } from "../../../../../components/AssetProperties/Skeleton"
import { AssetSensorInfoSkeleton } from "../../../../../components/AssetSensorInfo/Skeleton"
import { AssetTypeSkeleton } from "../../../../../components/AssetType/Skeleton"
import { Divider } from "../../../../../components/ui/Divider"

export const AssetDetailsSkeleton = () => (
  <>
    <AssetDescriptionSkeleton />
    <Divider />
    <AssetTypeSkeleton />
    <Divider />
    <AssetSensorInfoSkeleton />
    <Divider />
    <AssetPropertiesSkeleton />
  </>
)
