import {
  ChangeEventHandler,
  ForwardedRef,
  HtmlHTMLAttributes,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react"
import { Button } from "../ui/Button"
import {
  Container,
  PlaceholderContainer,
  ImageOptions,
  RemoveImage,
  Wrapper,
  ErrorText,
  ErrorLoadImageText,
  EditImage,
} from "./styles"
import { Image } from "phosphor-react"
import { Skeleton } from "@mui/material"

interface ImageProps extends HtmlHTMLAttributes<HTMLInputElement> {
  src?: string
  editable?: boolean
  error?: string
  onReset?: () => void
  skeleton?: boolean
}

const useForwardRef = <T,>(ref: ForwardedRef<T>) => {
  const targetRef = useRef<T>(null)

  useEffect(() => {
    if (!ref) return

    if (typeof ref === "function") {
      ref(targetRef.current)
    } else {
      ref.current = targetRef.current
    }
  }, [ref])

  return targetRef
}

export const ImageContainer = forwardRef<HTMLInputElement, ImageProps>(
  (
    {
      src,
      skeleton = false,
      editable = false,
      onChange,
      error,
      onReset,
      ...props
    },
    ref
  ) => {
    const inputRef = useForwardRef<HTMLInputElement>(ref)

    const [loadError, setLoadError] = useState(false)

    const [file, setFile] = useState<File>()

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const files = event.target.files

      if (!files) return
      if (files.length === 0) return

      setFile(files[0])
      console.log(files[0])
    }

    const handleAddImage = () => {
      setLoadError(false)
      inputRef.current?.click()
    }

    const handleRemoveImage = () => {
      setFile(undefined)
      if (inputRef.current) inputRef.current.value = ""
      setLoadError(false)
      if (onReset) onReset()
    }

    const getImageUrl = (): string | undefined => {
      if (file) return URL.createObjectURL(file)
      if (src) return src

      return undefined
    }

    return (
      <Wrapper>
        {skeleton ? (
          <Skeleton variant='rectangular' width={"100%"} height={200} />
        ) : (
          <>
            {(file || src) && editable && (
              <ImageOptions>
                <EditImage>
                  <Button variant='link' onClick={handleAddImage} type='submit'>
                    Alterar imagem
                  </Button>
                </EditImage>

                <RemoveImage>
                  <Button
                    variant='link'
                    onClick={handleRemoveImage}
                    type='submit'
                  >
                    Remover imagem
                  </Button>
                </RemoveImage>
              </ImageOptions>
            )}

            <Container>
              <PlaceholderContainer>
                {((!file && !src) || loadError) && <Image size={96} />}

                {(file || src) && !loadError && (
                  <img
                    src={getImageUrl()}
                    onError={(e) => setLoadError(true)}
                    alt='asset'
                  />
                )}

                {loadError && (
                  <ErrorLoadImageText>
                    Erro ao carregar imagem!
                  </ErrorLoadImageText>
                )}

                {editable && (
                  <>
                    <input
                      type='file'
                      onChange={(e) => {
                        handleChange(e)
                        if (onChange) onChange(e)
                      }}
                      ref={inputRef}
                      {...props}
                    />

                    {!file && (
                      <Button
                        variant='link'
                        onClick={handleAddImage}
                        type='submit'
                      >
                        Adicionar imagem
                      </Button>
                    )}
                  </>
                )}
              </PlaceholderContainer>
            </Container>

            {error && <ErrorText>{error}</ErrorText>}
          </>
        )}
      </Wrapper>
    )
  }
)

ImageContainer.displayName = "ImageContainer"
