import { Sensor } from "../../utils/entities/assets"
import { AssetWithoutSensor } from "./components/AssetWithoutSensor"
import { AssetWithSensor } from "./components/AssetWithSensor"
import { Container } from "./styles"

interface AssetSensorInfoProps {
  servicesConfigured?: Sensor
  hasServicesConfigured?: boolean
}

export const AssetSensorInfo = ({
  servicesConfigured,
  hasServicesConfigured = false,
}: AssetSensorInfoProps) => {
  return (
    <Container>
      {hasServicesConfigured ? (
        <AssetWithSensor
          servicesConfigured={servicesConfigured?.services}
          sensorName={servicesConfigured?.name}
        />
      ) : (
        <AssetWithoutSensor />
      )}
    </Container>
  )
}
