import { useState } from "react"
import { BookmarkSimple } from "phosphor-react"
import { Container, IconWrapper } from "./styles"

interface FavoriteProps {
  onClick?: () => void
}

export const Favorite = ({ onClick }: FavoriteProps) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
    if (onClick) {
      onClick()
    }
  }

  const weight = isClicked ? "fill" : "regular"

  return (
    <Container onClick={handleClick}>
      <IconWrapper $isClicked={isClicked}>
        <BookmarkSimple size={24} weight={weight} />
      </IconWrapper>
    </Container>
  )
}
