import { ArrowLeft, ArrowRight } from "phosphor-react"
import { IconButton } from "../../../ui/IconButton"
import { Container, Selector, Pages, Counter, Item, Page } from "./styles"
import { useMemo, useState } from "react"

interface FooterProps {
  total: number
  onChangeData: (itemsPerPage: number, page: number) => void
}

const ITEMS_PER_PAGE = [5, 10, 20]

const getValues = (size: number): number[] => {
  return Array.from({ length: size }, (_, k) => k + 1)
}

export const Footer = ({ total, onChangeData }: FooterProps) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(ITEMS_PER_PAGE[0])

  const pages = useMemo(
    () => Math.ceil(total / itemsPerPage),
    [total, itemsPerPage]
  )

  const [selected, setSelected] = useState<number>(1)

  const handleChangePage = (page: number) => {
    if (page >= 1 && page <= pages) {
      setSelected(page)
      onChangeData(itemsPerPage, page)
    }
  }

  const handleChangeItemsPerPage = (items: number) => {
    setItemsPerPage(items)
    handleChangePage(1)
  }

  return (
    <Container>
      <div className='pagination'>
        <Selector>
          <p>Exibir</p>
          <select
            onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
            defaultValue={ITEMS_PER_PAGE[0]}
          >
            {ITEMS_PER_PAGE.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </select>
          <p>items por página</p>
        </Selector>
        <Pages>
          <IconButton
            variant='secondary'
            icon={<ArrowLeft size={20} />}
            onClick={() => handleChangePage(selected - 1)}
            disabled={selected === 1}
          />
          <Counter>
            {pages <= 7 &&
              getValues(pages).map((v) => (
                <Page
                  key={v}
                  $selected={selected === v}
                  onClick={() => handleChangePage(v)}
                >
                  {v}
                </Page>
              ))}

            {selected < 5 && pages > 7 && (
              <>
                {getValues(pages)
                  .slice(0, 5)
                  .map((v) => (
                    <Page
                      key={v}
                      $selected={selected === v}
                      onClick={() => handleChangePage(v)}
                    >
                      {v}
                    </Page>
                  ))}
                <Item>...</Item>
                <Page
                  $selected={selected === pages}
                  onClick={() => handleChangePage(pages)}
                >
                  {pages}
                </Page>
              </>
            )}

            {selected >= 5 && selected <= pages - 4 && pages > 7 && (
              <>
                <Page
                  $selected={selected === 1}
                  onClick={() => handleChangePage(1)}
                >
                  1
                </Page>

                <Item>...</Item>

                <Item onClick={() => handleChangePage(selected - 1)}>
                  {selected - 1}
                </Item>

                <Page $selected={true}>{selected}</Page>

                <Item onClick={() => handleChangePage(selected + 1)}>
                  {selected + 1}
                </Item>

                <Item>...</Item>

                <Page
                  $selected={selected === pages}
                  onClick={() => handleChangePage(pages)}
                >
                  {pages}
                </Page>
              </>
            )}

            {selected > pages - 4 && pages > 7 && (
              <>
                <Page
                  $selected={selected === 1}
                  onClick={() => handleChangePage(1)}
                >
                  {1}
                </Page>
                <Item>...</Item>
                {getValues(pages)
                  .slice(pages - 5, pages)
                  .map((v) => (
                    <Page
                      key={v}
                      $selected={selected === v}
                      onClick={() => handleChangePage(v)}
                    >
                      {v}
                    </Page>
                  ))}
              </>
            )}
          </Counter>
          <IconButton
            variant='secondary'
            icon={<ArrowRight size={20} />}
            onClick={() => handleChangePage(selected + 1)}
            disabled={selected === pages}
          />
        </Pages>
      </div>
    </Container>
  )
}
