import {
  UncontrolledTreeEnvironment,
  Tree,
  TreeItemIndex,
  TreeItem,
  TreeItemRenderContext,
} from "react-complex-tree"
import { CustomDataProvider } from "./CustomDataProvider"
import { AssetData } from "../../../utils/entities/assets"
import { ReactNode } from "react"
import { TreeItemRenderer } from "./components/TreeItemRenderer"
import { TreeAssetsContainer } from "./styles"

interface TreeAssetsProps {
  dataProvider: CustomDataProvider
  handleOnClick: (itemIdx: TreeItemIndex) => void
  handleAsset: (id: string) => void
}

interface RenderItemProps {
  item: TreeItem<AssetData>
  depth: number
  children: ReactNode | null
  title: ReactNode
  arrow: ReactNode
  context: TreeItemRenderContext<"expandedItems">
}

export const TreeAssets = ({
  dataProvider,
  handleOnClick,
  handleAsset,
}: TreeAssetsProps) => {
  const renderItem = ({ item, ...props }: RenderItemProps) => {
    return (
      <TreeItemRenderer
        {...props}
        childrenAsset={item.children}
        itemIdx={item.index}
        type={item.data.type}
        label={item.data.name}
        handleOnClick={handleOnClick}
        handleAsset={handleAsset}
      />
    )
  }

  return (
    <TreeAssetsContainer>
      <UncontrolledTreeEnvironment
        canDragAndDrop
        canDropOnFolder
        canReorderItems
        dataProvider={dataProvider}
        getItemTitle={(item) => item.data.name}
        viewState={{ "tree-1": { expandedItems: ["root"] } }}
        renderDepthOffset={20}
        renderItemTitle={({ title }) => <span>{title}</span>}
        renderItem={renderItem}
        renderTreeContainer={({ children, containerProps }) => (
          <div {...containerProps}>{children}</div>
        )}
        renderItemsContainer={({ children, containerProps }) => (
          <ul {...containerProps}>{children}</ul>
        )}
      >
        <Tree treeId='tree-1' rootItem='master' treeLabel='Tree Example' />
      </UncontrolledTreeEnvironment>
    </TreeAssetsContainer>
  )
}
