import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Wrapper = styled.form`
  min-height: 85vh;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.gray._400};
  background-color: ${(props) => props.theme.palette.gray._50};
`

export const AssetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px 16px 12px 16px;

  @media ${media.small} {
    flex-direction: row;
  }
`

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
`

export const AssetDescription = styled.div`
  padding: 12px 16px 12px 16px;
`

export const PropertiesContainer = styled.div`
  padding: 12px 16px 12px 16px;

  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;

  gap: 16px;

  text-transform: capitalize;
`
