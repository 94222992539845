import styled from "styled-components"
import { media } from "../../utils/media-queries"

export const Container = styled.div`
  background-color: ${(props) => props.theme.palette.gray._300};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.gray._400};
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: ${(props) => props.theme.palette.gray._500};
  overflow: hidden;

  width: 100%;
  height: 200px;

  @media ${media.small} {
    width: 243px;
  }
`

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  input {
    display: none;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: start;

  gap: 2px;

  @media ${media.small} {
    min-width: 243px;
  }
`

export const ImageOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 12px;

  width: 100%;

  button {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.xs.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
  }
`

export const EditImage = styled.div`
  button {
    color: ${(props) => props.theme.palette.gray._500};
  }

  &:hover {
    button {
      color: ${(props) => props.theme.palette.gray._600};
    }
  }
`

export const RemoveImage = styled.div`
  button {
    color: ${(props) => props.theme.palette.error.main};
  }
`

export const ContainerStorybook = styled.div`
  display: flex;
  left: 0;
  justify-content: center;
  flex-direction: column;
  width: 223px;
  height: 215px;
  gap: 20px;
`

export const ErrorText = styled.p`
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const ErrorLoadImageText = styled.p`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.md.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.md.lineHeight};
`
