import { Skeleton } from "@mui/material"
import { Title } from "../Title"
import { Container, WrapperText } from "./styles"

interface TitleAndTextProps {
  title?: string
  text?: string
  skeleton?: boolean
}

const SkeletonPlaceholder = () => (
  <>
    <Skeleton variant='text' width={100} />
    <WrapperText>
      <Skeleton variant='text' />
      <Skeleton variant='text' />
    </WrapperText>
  </>
)

export const TitleAndText = ({
  title = "",
  text = "",
  skeleton = false,
}: TitleAndTextProps) => {
  return (
    <Container>
      {skeleton ? (
        <SkeletonPlaceholder />
      ) : (
        <>
          <Title label={title} size='xs' weight='semibold' />
          <WrapperText>
            <p>{text}</p>
          </WrapperText>
        </>
      )}
    </Container>
  )
}
