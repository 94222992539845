/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react"
import { CaretDown } from "phosphor-react"
import { CellContent } from "../../tableCss.styled"
import { CheckBoxUI } from "../Check"
import { ListDetailsGateways } from "../../../../../pages/v3/Gateways/styles/styles"
import { OnOff } from "../../../../../pages/v3/Gateways/components/OnOff"

interface RowTableProps<T, U, W> {
  row: T
  config: U[]
  onChange: (value: number, check: boolean) => void
  renderExpandContent: W[]
}

export const RowsTableCss = <
  T extends Record<string, any>,
  U extends Record<string, any>,
  W extends Record<string, any>,
>(
  props: RowTableProps<T, U, W>
) => {
  const { row, config, onChange, renderExpandContent } = props
  const [expandedRow, setExpandedRow] = useState(false)

  let statusGateway: boolean = false
  let nowInMiliseconds = new Date().getTime()

  if (row.value?.time) {
    let date = new Date(row.value.time * 1000).getTime()
    statusGateway = nowInMiliseconds - date <= 15000 ? true : false
  } else {
    statusGateway = false
  }

  const showRow = (id: number) => {
    setExpandedRow(!expandedRow)
  }

  const [checked, setChecked] = useState(false)

  const handleCheckboxChange = () => {
    setChecked(!checked)
    onChange(row.id, checked)
  }

  const renderedCells = config.map((column) => {
    let showOnOff = false
    if (column.label === "Gateway") {
      showOnOff = true
    }
    const clName = `${showOnOff ? "mobile-view-over gateway-name" : "mobile-view-over"}`

    return (
      <div key={column.label} className='cell-column'>
        <span className='mobile-view'>{column.label}</span>
        <div className={clName}>
          {showOnOff && <OnOff status={statusGateway} />}
          <p>{column.render(row)}</p>
        </div>
      </div>
    )
  })

  const renderDetails = renderExpandContent.map((item) => {
    return (
      <div key={item.label} className='list-gateway-info'>
        <span>{item.label} </span>
        <p>{item.render(row)}</p>
      </div>
    )
  })

  return (
    <>
      <div className='table-row' key={row.id}>
        <div className='cell-column'>
          <CheckBoxUI isCheck={checked} onClick={handleCheckboxChange} />
        </div>
        {renderedCells}
        <div className='cell-column expand-content-mobile'>
          <CellContent $isOpen={expandedRow} onClick={() => showRow(row.id)}>
            <div className='arrow-container'>
              <CaretDown size={16} />
            </div>
          </CellContent>
        </div>
      </div>
      <ListDetailsGateways $isOpen={expandedRow}>
        <div className='table-row-expand-group'>
          <div className='list-items'>{renderDetails}</div>
        </div>
      </ListDetailsGateways>
    </>
  )
}
