import styled from "styled-components"

type CardIconAndTitleProps = {
  $status: "read" | "unread"
}

type CardIconProps = {
  type: "notification" | "message"
}

export const CardIconAndTitle = styled.div<CardIconAndTitleProps>`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: ${(props) => props.theme.typography.text.sm};

  ${(props) =>
    props.$status === "read" &&
    `
      color: ${props.theme.palette.gray._700};
    `}
`

export const CardTag = styled.div`
  font-size: ${(props) => props.theme.typography.text.sm};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
`

export const CardIcon = styled.div<CardIconProps>`
  display: flex;
  ${(props) =>
    props.type === "notification" &&
    `
        display: none;
    `}
`
