import styled from "styled-components"

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.selected
      ? (props) => props.theme.palette.gray._50
      : (props) => props.theme.palette.gray._300};

  min-width: 111px;
  min-height: 29px;

  border-radius: 10px 10px 0px 0px;

  border-top: 1px solid ${(props) => props.theme.palette.gray._400};
  border-right: 1px solid ${(props) => props.theme.palette.gray._400};
  border-left: 1px solid ${(props) => props.theme.palette.gray._400};

  margin-bottom: -1px;
`
