import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {
  AssetDetailsData,
  AssetPropertiesDTO,
  AssetTreeData,
  Sensor,
} from "../../utils/entities/assets"

import { AssetSchema } from "../../pages/CreateAsset/schema"

interface AssetApi {
  id: string
  children: string[]
  name: string
  type: number
  isRoot?: boolean
}

const pause = (duration: number | undefined) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

const createAssetItem = (asset: AssetApi) => {
  return {
    index: asset.id,
    children: asset.children,
    data: {
      id: asset.id,
      name: asset.name,
      type: Number(asset.type),
    },
    isFolder: asset.children.length > 0,
    canMove: false,
    canRename: false,
  }
}

export const assetsApi = createApi({
  reducerPath: "assets",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3005",
    fetchFn: async (...args) => {
      //"This section ('await pause') of code is for testing purposes only
      //and will be removed before production deployment."
      await pause(1000)
      return fetch(...args)
    },
  }),
  tagTypes: ["Assets", "Tree"],
  endpoints: (build) => ({
    fetchAssets: build.query<AssetTreeData, void>({
      query: () => {
        return {
          url: "/assets",
          method: "GET",
        }
      },
      providesTags: (response) => {
        if (!response) return [{ type: "Assets", id: "LIST" }]

        const keys = Object.keys(response)

        return keys.map((key) => ({
          type: "Assets",
          id: response[key].data.id,
        }))
      },
      transformResponse: (response: AssetApi[]) => {
        const tree: AssetTreeData = {}

        const rootChildren: string[] = []

        response.forEach((asset) => {
          if (asset.isRoot) {
            rootChildren.push(asset.id)
          }

          tree[asset.id] = createAssetItem(asset)
        })

        tree["master"] = {
          index: "master",
          children: rootChildren,
          data: {
            id: "",
            name: "Assets",
            type: 1,
          },
          isFolder: rootChildren.length > 0,
          canMove: false,
          canRename: false,
        }

        return tree
      },
    }),
    fetchAssetTree: build.query<AssetApi, string>({
      query: (id) => {
        return {
          url: `/assets/${id}`,
          method: "GET",
        }
      },
      providesTags: (response) => {
        return [{ type: "Tree", id: response?.id }]
      },
    }),
    updateAssetTree: build.mutation<
      AssetApi,
      { id: string; children: string[] }
    >({
      query: ({ id, children }) => {
        return {
          url: `/assets/${id}`,
          method: "PATCH",
          body: { children },
        }
      },
      invalidatesTags: (response) => {
        if (!response) return []

        return [
          { type: "Assets", id: response.id },
          { type: "Tree", id: response.id },
        ]
      },
    }),
    fetchAsset: build.query<AssetDetailsData, string>({
      query: (assetId) => {
        return {
          url: `/assets-details/${assetId}`,
          method: "GET",
        }
      },
    }),
    addAssetTree: build.mutation<
      void,
      { id: string; name: string; type: number }
    >({
      query: ({ id, name, type }) => {
        return {
          method: "POST",
          url: "/assets",
          body: {
            id,
            children: [],
            name,
            type,
          },
        }
      },
    }),
    addAsset: build.mutation<AssetDetailsData, AssetSchema>({
      query: ({ description, name, properties, type, sensorId }) => {
        const id = Math.round(Math.random() * 100) + 10

        const propertiesObj: AssetPropertiesDTO = {}

        properties.forEach(({ property, value }) => {
          propertiesObj[property] = value
        })

        return {
          method: "POST",
          url: "/assets-details",
          body: {
            id: id.toString(),
            createdAt: Date.now(),
            description,
            name,
            historicalData: [],
            sensor: {
              name: sensorId,
              id: sensorId,
              serialNumber: sensorId,
              services: [],
            },
            properties: propertiesObj,
            type: Number(type),
          } as AssetDetailsData,
        }
      },

      invalidatesTags: (response) => {
        return [{ type: "Assets", id: response?.id }]
      },
    }),
    fetchSensors: build.query<Sensor[], number>({
      query: (sensor) => {
        return {
          url: `/sensors`,
          params: {
            index: sensor,
          },
          method: "GET",
        }
      },
    }),
  }),
})

export const {
  useFetchAssetsQuery,
  useFetchAssetQuery,
  useAddAssetTreeMutation,
  useFetchSensorsQuery,
  useAddAssetMutation,
  useFetchAssetTreeQuery,
  useUpdateAssetTreeMutation,
} = assetsApi
